<template>
    <div class="card">
        <div class="card-body">
            <div v-if="!this.palletBarcode" class="alert alert-info text-center">
                <div v-if="palletLoading"><i class="fas fa-spinner fa-spin"></i></div>
                <div>Отсканируйте ШК паллеты</div>
            </div>
            <div v-else-if="!zoneBarcode" class="alert alert-info text-center">
                <div v-if="zoneLoading"><i class="fas fa-spinner fa-spin"></i></div>
                <div>Паллета: PT{{ parseInt(this.palletBarcode.substr(2)) }}</div>
                <div>Отсканируйте ШК ячейки</div>
            </div>
            <div v-else class="text-center">
                <div class="alert alert-light">
                    <div>Ячейка: {{ zone.name }}</div>
                    <div>Подтверждено ящиков: {{ boxBarcodes.length }} из {{ barcodeRequiredCount }}</div>
                </div>
                <div class="alert alert-info">Для подтверждения перемещения паллеты, отсканируйте
                    {{ barcodeRequiredCount - boxBarcodes.length }} ШК ящиков
                </div>
            </div>
        </div>
        <control-panel v-if="palletBarcode">
            <button @click="reset();" class="btn btn-warning">
                <b-icon-arrow-repeat font-scale="1.2"/>
                Сбросить
            </button>
        </control-panel>
    </div>
</template>

<script>
import Barcode from "@utils/Barcode";
import {STOCK_ENDPOINT} from "@utils/endpoints";
import {findIndex} from 'lodash';
import ControlPanel from "@components/_common/ControlPanel";

export default {
    components: {
        ControlPanel
    },
    name: 'PalletMovement',
    data() {
        return {
            palletBarcode: null,
            palletLoading: false,

            zoneBarcode: null,
            zoneLoading: false,
            zone: null,

            boxBarcodes: [],
        };
    },
    computed: {
        barcodeRequiredCount() {
            if (!this.zone) {
                return null;
            }

            if (this.zone.stockItems.length >= 5) {
                return 5;
            }

            return this.zone.stockItems.length;
        }
    },
    methods: {
        onBarcodeScanned(data) {
            if (!this.palletBarcode && data.barcode.startsWith(Barcode.palletBarcodePrefix())) {
                this.palletBarcodeScanned(data.barcode);
                return;
            }

            if (this.palletBarcode && !this.zoneBarcode && data.barcode.startsWith(Barcode.zoneBarcodePrefix())) {
                this.zoneBarcodeScanned(data.barcode);
                return;
            }

            if (this.palletBarcode && this.zoneBarcode && data.barcode.startsWith(Barcode.boxBarcodePrefix())) {
                this.boxBarcodeScanned(data.barcode);
                return;
            }

            this.$toast.error('Неверный тип ШК');
        },
        palletBarcodeScanned(barcode) {
            if (this.palletLoading) {
                this.$toast.warning('Дождитесь завершения предыдущей операции');
                return;
            }

            this.palletLoading = true;
            this.$http.get(STOCK_ENDPOINT + `/pallet/check/${barcode}`, {
                params: {
                    without_loading: true,
                }
            }).then(response => {
                if (response.data.exists === true) {
                    this.$toast.error('Паллет уже используется');
                    return;
                }

                this.palletBarcode = barcode;
            }).catch(response => {
                this.$toast.error(response.data.message);
            }).finally(() => {
                this.palletLoading = false;
            });
        },
        zoneBarcodeScanned(barcode) {
            if (this.zoneLoading) {
                this.$toast.warning('Дождитесь завершения предыдущей операции');
                return;
            }

            this.zoneLoading = true;
            this.$http.get(STOCK_ENDPOINT + `/location`, {
                params: {
                    without_loading: true,
                    type: 'zone',
                    barcode: barcode,
                    with: [
                        'stockItems.entity.box.barcode'
                    ]
                }
            }).then(response => {
                let location = response.data;
                if (!location.stockItems.length) {
                    this.$toast.warning('Ячейка пуста');
                    return;
                }

                this.zoneBarcode = barcode;
                this.zone = location;
            }).catch(response => {
                this.$toast.error(response.data.message);
            }).finally(() => {
                this.zoneLoading = false;
            });
        },
        boxBarcodeScanned(barcode) {
            if (!this.barcodeRequiredCount) {
                this.$toast.error('Не установлена ячейка');
                return;
            }

            let index = findIndex(this.zone.stockItems, i => i.entity.box.barcode.code === barcode);
            let existsing = findIndex(this.boxBarcodes, b => b === barcode);
            if (index === -1) {
                this.$toast.error(`В ячейке ${this.zone.name} нет этого ящика`);
                return;
            }

            if (existsing !== -1) {
                this.$toast.warning('Этот ящик уже был отсканирован');
                return;
            }

            this.boxBarcodes.push(barcode);

            if (this.boxBarcodes.length >= this.barcodeRequiredCount) {
                this.fillPallet();
            } else {
                this.$toast.success(`Осталось ${this.barcodeRequiredCount - this.boxBarcodes.length} ящиков`);
            }
        },
        fillPallet() {
            this.$http.post(STOCK_ENDPOINT + '/migrate', {
                location_from_type: 'zone',
                location_from_barcode: this.zoneBarcode,
                location_to_type: 'pallet',
                location_to_barcode: this.palletBarcode
            }).then(response => {
                this.$toast.success('Паллета создана и перемещена на склад готовой продукции');
            }).catch(response => {
                this.$toast.error(response.data.message);
            }).finally(() => {
                this.reset();
            });
        },
        reset() {
            this.palletBarcode = null;
            this.zoneBarcode = null;
            this.zone = null;
            this.boxBarcodes = [];
        }
    },
    mounted() {
        this.$root.listenToBarcodeScanning(this);
    }
}
</script>

<style scoped>

</style>
